<template>
  <div
    v-editable="blok"
    class="card"
    :class="
      blok.media &&
      blok.media[0]?.component == 'LayeredPicture' &&
      'card--has-picture'
    "
  >
    <div v-if="blok.media && blok.media[0]" class="card__media">
      <template v-if="blok.media[0].component === 'Icon'">
        <Icon
          v-editable="blok"
          :icon="(blok.media[0].name as IconBlokProps['name'])"
        />
      </template>
      <template v-else-if="blok.media[0].component === 'LayeredPicture'">
        <Picture
          :blok="blok.media[0]"
          :thumbnail="makeThumbnail(blok.media[0] as LayeredPictureBlokProps).thumbnail"
          :thumbnailposition="makeThumbnail(blok.media[0] as LayeredPictureBlokProps).thumbnailposition"
          position="right"
          loading="lazy"
        />
      </template>
    </div>
    <CustomRTE v-if="blok.text" class="card__text" :blok="blok.text[0]" />
    <div v-if="blok.link && blok.link[0]" class="card__link">
      <CTA :blok="blok.link[0]" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CardBlokProps, IconBlokProps, LayeredPictureBlokProps } from '@/types';

// Props
defineProps<{
  blok: CardBlokProps;
}>();

const makeThumbnail = (src: LayeredPictureBlokProps) => ({
  thumbnail:
    src.thumbnail && src.thumbnail.filename ? src.thumbnail : undefined,
  thumbnailposition: src.thumbnail_position
    ? src.thumbnail_position
    : undefined,
});
</script>

<style lang="scss">
.card {
  &__media {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .icon {
      margin-block-end: var(--spacing-xs);
      width: 40px;

      @media all and #{$mq-m-min} {
        width: 65px;
      }
    }

    .picture {
      margin-block-end: var(--spacing-s);
    }
  }

  &__link {
    display: inline-block;
    margin-block-start: var(--spacing-s);
  }

  /* Adjustments for Custom RTE */
  &--has-picture .custom-rte {
    /* Limit width only when there's a Picture */
    max-width: 90%;
  }

  .custom-rte__heading + .custom-rte__text {
    /* Reduce vertical spacing between heading and text */
    margin-block-start: var(--spacing-xxs);
  }

  .opacity-75 {
    opacity: 0.75;
  }
}
</style>
